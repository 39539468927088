import { nanoid } from 'nanoid'

type Match = {
  id: number
  name: string
  datetime: string
}

type AvailabilityResponse = {
  no_schedule: boolean
  nearest: Match
  current: Match | null
  current_time: string
}

const parseDateTime = (dateTime: string) => {
  const date = new Date(dateTime)

  return {
    date: date.toLocaleDateString('Ru-ru'),
    time: date.toLocaleTimeString('Ru-ru').split(':').slice(0, 2).join(':'),
  }
}

export const fetchAvailability = async () => {
  const url = 'https://api.kuper-akbars.ru/schedule/'
  const response = await fetch(url)
  const json: AvailabilityResponse = await response.json()

  // return {
  //   current: null,
  //   nearest: {
  //     name: 'asdf',
  //     date: '1.1.1',
  //     time: '000',
  //   },
  // }

  return {
    current:
      json.current === null
        ? null
        : {
            id: json.current.id,
            name: json.current.name,
            date: parseDateTime(json.current.datetime).date,
            time: parseDateTime(json.current.datetime).time,
          },
    nearest: {
      id: json.nearest.id,
      name: json.nearest.name,
      date: parseDateTime(json.nearest.datetime).date,
      time: parseDateTime(json.nearest.datetime).time,
    },
  }
}

type CouponResponse = {
  code: string
  level: number
  schedule_id: number
}

export const fetchCoupon = async (matchId: number | null, level: number) => {
  if (!matchId) return

  const localCoupon = localStorage.getItem(`coupon:${matchId}`)

  if (localCoupon) {
    return localCoupon
  }

  const id = nanoid()
  const url = `https://api.kuper-akbars.ru/promocode/?unique_identifier=${id}&level=${level}`
  const response = await fetch(url)

  if (!response.ok) {
    return null
  }

  const json: CouponResponse = await response.json()
  localStorage.setItem(`coupon:${matchId}`, json.code)

  return json.code
}
