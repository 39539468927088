const YM_ID = 98876032

export const analyticEvent = (event: string) => {
  console.log(event)
  // @ts-ignore
  window.ym(YM_ID, 'reachGoal', event)
}

// no-matches-popup-shown
// wrong-location-popup-shown
// ar-page-opened
// level-1-achieved
// level-2-achieved
// level-3-achieved
// round-1-started
// round-2-started
// round-3-started
// play-again-button-clicked
// get-coupon-button-clicked
// copy-coupon-button-clicked
// share-result-button-clicked
// go-to-kuper-button-clicked
